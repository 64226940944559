.PeriodoDropDown .icono-centrado {
  vertical-align: text-bottom;
}

/* Clases del calendario. */
.PeriodoDropDown .cal-year {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fa;
}
.PeriodoDropDown .cal-month {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}
.PeriodoDropDown .cal-opcion {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.PeriodoDropDown .cal-no-btn {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 1.8rem;
  padding: 0;
}
.PeriodoDropDown .cal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 0.9rem;
  height: 1.8rem;
  padding: 0;
}
.PeriodoDropDown .cal-btn:hover,
.PeriodoDropDown .cal-btn:focus {
  background-color: rgba(0, 0, 0, 0.25);
}
.PeriodoDropDown .cal-text-sm {
  font-size: 0.75rem;
}
.PeriodoDropDown .cal-width {
  width: 170px;
}
