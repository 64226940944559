// Include any default variable overrides here
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;700&display=swap');

$font-family-base: 'Montserrat', sans-serif;

// Titulos: tamaños.
$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.15rem;
$h4-font-size: 1rem;
$h5-font-size: 0.85rem;
$h6-font-size: 0.7rem;

// Paleta de colores.
$primary: #b24a3b;
$secondary: #363636;
$info: #935123;
$success: #f4ebd2;
$light: #f9f9f9;
$dark: #222222;
$white: #ffffff;
$warning: #f1f1f1;
$color-amarillo: #f4ebd2;
// $color-amarillo-light: mix($color-amarillo, $white, 25%);
$color-amarillo-light: #fcfaf4;

// Colores de textos de 'nav' en 'header'.
$nav-link-color: $light;
$nav-link-hover-color: mix(black, $nav-link-color, 20%);

// Colores de componente Accordion.
$accordion-border-width: 0px;
$accordion-button-padding-y: 0.25rem;
$accordion-button-padding-x: 1rem;
$accordion-button-bg: #f9f9f9;
$accordion-button-active-bg: #f9f9f9;
$accordion-button-active-color: black;
// $accordion-button-focus-box-shadow: 0 0 0 0.05rem rgba(black, 0.25);
$accordion-button-focus-box-shadow: 0 0 0 0rem rgba(black, 0.25);
$accordion-icon-color: black;
$accordion-icon-active-color: black;

// Colores de form-file input component.
$form-file-button-color: #7f7f7f;
$form-file-button-bg: $dark;
$form-file-button-hover-bg: $color-amarillo;

@import '../node_modules/bootstrap/scss/bootstrap';
