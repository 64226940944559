.PanelVentaEnergia .altura-navbar {
  height: 48px;
}
.PanelVentaEnergia .bgc-gris {
  background-color: #e4ebec;
}
.PanelVentaEnergia .text-small {
  font-size: 0.6rem;
}
.PanelVentaEnergia .warning-number {
  color: #548235;
}
.PanelVentaEnergia .ancho-45 {
  width: 43.5%;
}
.PanelVentaEnergia .ancho-22 {
  width: 22.5%;
}
