.Factura .text-small {
  font-size: 0.73rem;
}

.Factura .image-small {
  width: 6rem;
}
.Factura .column-ancho {
  width: 40%;
}
.Factura .column-totales {
  width: auto;
}
@media (min-width: 768px) {
  .Factura .column-ancho {
    width: auto;
  }
  .Factura .column-totales {
    width: 15%;
  }
}
.Factura .factura-datos-padding td {
  padding: 3px;
}
.Factura .factura-datos-padding th {
  padding: 3px;
}
.Factura .warning-number {
  color: #548235;
}
