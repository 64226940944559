/* Altura de los Navbars en cada Panel. */
.PanelPagos .altura-navbar {
  height: 48px;
}

.PanelPagos .button-link {
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: rgba(178, 74, 59, 0.5);
  color: rgba(178, 74, 59, 0.75);
  background-color: rgba(178, 74, 59, 0.15);
  font-family: 'sans-serif';
  font-weight: bold;
  font-size: 0.6rem;
  padding: 0;
}
.PanelPagos .button-link:focus,
.PanelPagos .button-link:hover {
  background-color: rgba(178, 74, 59, 0.25);
}
