.MainPanel .img-bienvenido {
  width: 100%;
  height: 35vh;
  object-fit: cover;
  object-fit: center;
}
.MainPanel .img-proyecto {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  object-fit: center;
}
