/* Altura de los Navbars en cada Panel. */
.PanelComunidad .altura-navbar {
  height: 48px;
}
.PanelComunidad .ancho-15 {
  width: 15%;
}
.PanelComunidad .rectangle-placeholder {
  width: auto;
  height: 190px;
  color: white;
  border-right: 1px solid white;
  background-color: #262626;
  border-radius: 4px;
}
.PanelMedidor .autocomplete {
  max-width: 140px;
}
@media (min-width: 576px) {
  .PanelMedidor .autocomplete {
    max-width: 166px;
  }
}
