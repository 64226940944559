.ProcessStatus {
  width: auto;
}
@media (min-width: 576px) {
  .ProcessStatus {
    width: 420px;
  }
}
.ProcessStatus .text-small {
  font-size: 0.7rem;
}
.ProcessStatus .icono-centrado {
  vertical-align: text-bottom;
}
.ProcessStatus .btn-actualizar {
  padding: 2px 4px;
}
