.CambiarImagenModal .size-imagen {
  width: auto; /** 280px; */
  height: 320px; /** 280px; */
  object-fit: cover;
}
@media (min-width: 576px) {
  .CambiarImagenModal .size-imagen {
    width: auto;
    height: 240px;
    object-fit: cover;
  }
}
.CambiarImagenModal .icono-centrado {
  vertical-align: text-bottom;
}
.CambiarImagenModal .texto-cabecera {
  border: none;
  color: #f9f9f9;
  background-color: #222222;
  padding: 8px 16px;
}

.CambiarImagenModal .texto-cabecera:hover {
  color: #f4ebd2;
}

.CambiarImagenModal .crop-container {
  position: relative;
  height: 240px;
}
