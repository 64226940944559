.PanelRentabilidad .altura-navbar {
  height: 48px;
}
.PanelRentabilidad .bgc-gris {
  background-color: #e4ebec;
}
.PanelRentabilidad .text-small {
  font-size: 0.6rem;
}
.PanelRentabilidad .warning-number {
  color: #548235;
}
.PanelRentabilidad .ancho-45 {
  width: 43.5%;
}
.PanelRentabilidad .ancho-22 {
  width: 22.5%;
}
.PanelRentabilidad .color-gris {
  color: #6d757c;
}
.PanelRentabilidad .negative-number {
  color: #548235;
}
