/* Altura de los Navbars en cada Panel. */
.PanelMedidor .altura-navbar {
  height: 48px;
}
.PanelMedidor .autocomplete {
  max-width: 120px;
}
@media (min-width: 576px) {
  .PanelMedidor .autocomplete {
    max-width: 146px;
  }
}
.PanelMedidor .color-gris {
  color: #6d757c;
}
.PanelMedidor .column-ancho {
  width: 33%;
}
.PanelMedidor .text-small {
  font-size: 0.6rem;
}
