.EPlatFooter {
  color: #7f7f7f;
  background-color: #fcfaf4;
}
.EPlatFooter .bold {
  color: #595959;
}
.EPlatFooter .soft {
  color: #a6a6a6;
}
