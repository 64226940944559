.Departamento .altura-navbar {
  height: 48px;
}
.Departamento .color-gris {
  color: #6d757c;
}
.Departamento .button-link {
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: rgba(178, 74, 59, 0.5);
  color: rgba(178, 74, 59, 0.75);
  background-color: rgba(178, 74, 59, 0.15);
  font-family: 'sans-serif';
  font-weight: bold;
  font-size: 0.6rem;
  padding: 0;
}
.Departamento .button-link:focus,
.Departamento .button-link:hover {
  background-color: rgba(178, 74, 59, 0.25);
}
.Departamento .input-cod-servicio {
  width: 100%;
  font-weight: bold;
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  background-color: white;
  border-radius: 0.2rem;
  border: 1px solid #ced4da;
}
