.EPlatHeader .icono-centrado {
  vertical-align: text-bottom;
}
.EPlatHeader .texto-cabecera {
  border: none;
  color: #f9f9f9;
  background-color: #222222;
  padding: 8px 16px;
}

.EPlatHeader .texto-cabecera:hover {
  color: #f4ebd2;
}
