.CuentaCorriente .altura-navbar {
  height: 48px;
}
.CuentaCorriente .text-small {
  font-size: 0.6rem;
}
.CuentaCorriente .negative-number {
  color: #548235;
}
.CuentaCorriente .ancho-input {
  max-width: 102px;
}
.CuentaCorriente .bgc-gris {
  background-color: #f2f2f2;
}
