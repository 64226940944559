.EPlatMenu .btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-weight: 700;
  font-size: 0.8rem;
  color: #6d757c;
  border: 0;
}
.EPlatMenu .btn-toggle:hover,
.EPlatMenu .btn-toggle:focus {
  color: #222222;
}
.EPlatMenu .btn-toggle::before {
  width: 1.25em;
  margin-right: 0.25rem;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
}
.EPlatMenu .btn-toggle[aria-expanded='true']::before {
  transform: rotate(90deg);
}
.EPlatMenu .btn-toggle[aria-expanded='true']:hover {
  color: black;
}
.EPlatMenu .btn-toggle-nav a {
  display: inline-flex;
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
  font-weight: 500;
  font-size: 0.8rem;
  color: #222222;
  text-decoration: none;
}
.EPlatMenu .btn-toggle-nav .color-active {
  color: #983f33;
}
.EPlatMenu .btn-toggle-nav a:hover,
.EPlatMenu .btn-toggle-nav a:focus {
  color: #b24a3b;
}
.EPlatMenu .icono-centrado {
  vertical-align: text-bottom;
}
