.LoginHome .eplat-login-fondo {
  height: 128px;
  background-size: cover;
  background-position: center;
  background-image: url('/public/images/eplat-portada.jpg');
}
.LoginHome .eplat-login-remain-altura {
  height: calc(100vh - 128px);
}
@media (min-width: 768px) {
  .LoginHome .eplat-login-fondo {
    height: 100vh;
    min-height: 560px;
  }
  .LoginHome .eplat-login-remain-altura {
    height: 100vh;
  }
}
