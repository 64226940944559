.PanelCompraEnergia .altura-navbar {
  height: 48px;
}
.PanelCompraEnergia .bgc-gris {
  background-color: #e4ebec;
}
.PanelCompraEnergia .text-small {
  font-size: 0.6rem;
}
.PanelCompraEnergia .warning-number {
  color: #548235;
}
.PanelCompraEnergia .ancho-45 {
  width: 43.5%;
}
.PanelCompraEnergia .ancho-22 {
  width: 22.5%;
}
.PanelCompraEnergia .color-gris {
  color: #6d757c;
}
