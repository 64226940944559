/* Altura de los Navbars en cada Panel. */
.Medidor .altura-navbar {
  height: 48px;
}
.Medidor .autocomplete {
  max-width: 102px;
}
@media (min-width: 576px) {
  .Medidor .autocomplete {
    max-width: 166px;
  }
}
