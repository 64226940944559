.StepStatus {
  width: auto;
}
@media (min-width: 576px) {
  .StepStatus {
    width: 420px;
  }
}
.StepStatus .cabecera {
  color: black;
}
.StepStatus .circulo {
  padding: 0;
  margin: 0;
  background-color: white;
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.StepStatus .ejecutado::before {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: text-bottom;
  content: url('/public/images/OK.png');
}
.StepStatus .no-ejecutado::before {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: text-bottom;
  content: url('/public/images/NOK.png');
}
.StepStatus .text-small {
  font-size: 0.8rem;
}
.StepStatus .button-link {
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: rgba(178, 74, 59, 0.5);
  color: rgba(178, 74, 59, 0.75);
  background-color: rgba(178, 74, 59, 0.15);
  font-family: 'sans-serif';
  font-weight: bold;
  font-size: 0.6rem;
  padding: 0;
}
.StepStatus .button-link:focus,
.StepStatus .button-link:hover {
  background-color: rgba(178, 74, 59, 0.25);
}
