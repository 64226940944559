.PanelBalanceEnergia .altura-navbar {
  height: 48px;
}

.PanelBalanceEnergia .misColores .nav-link {
  color: #dfdfdf !important;
}
.PanelBalanceEnergia .misColores .nav-link:hover,
.PanelBalanceEnergia .misColores .nav-link:focus {
  color: #848484 !important;
}
.PanelBalanceEnergia .misColores .nav-link.active {
  color: #222222 !important;
}

.PanelBalanceEnergia .anchoRadioButton {
  min-width: 4.5rem;
}

.PanelBalanceEnergia .bgc-gris {
  background-color: #e4ebec;
}
.PanelBalanceEnergia .ancho-20 {
  width: 20%;
}
.PanelBalanceEnergia .ancho-25 {
  width: 25%;
}
.PanelBalanceEnergia .ancho-40 {
  width: 40%;
}
