.Boleta .text-small {
  font-size: 0.73rem;
}

.Boleta .image-small {
  width: 6rem;
}
.Boleta .column-ancho {
  width: 40%;
}
.Boleta .column-totales {
  width: auto;
}
@media (min-width: 768px) {
  .Boleta .column-ancho {
    width: auto;
  }
  .Boleta .column-totales {
    width: 15%;
  }
}
.Boleta .boleta-datos-padding td {
  padding: 3px;
}
.Boleta .boleta-datos-padding th {
  padding: 3px;
}
